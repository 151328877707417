import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import analysisView from "../images/analysis-view.png";
import toolPanels from "../images/toolpanels.png";
import multiHistory from "../images/multi-history.png";

import { HeaderSection, SectionBreak, InfoSection } from "../components/sections";

const LoginPage = () => (
    <Layout>
        <SEO title="Analyze Your Own Data" />
        <HeaderSection
            title="Analyze Your Own Data"
            subtitle="Eliminate the back-and-forth with a bioinformatician by exploring the data yourself using a user-friendly web application with the same tools as generally available on the command terminal."
        />
        <SectionBreak
            title="Use an Intuitive Graphical Interface to Learn Faster"
            subtitle="Galaxy Pro is accessed via a web browser allowing you to explore the available tools and data with ease."
        />
        <InfoSection
            image={analysisView}
            facts={[
                {
                    title: "An Intuitive Web Interface",
                    blurb: "Simple to grasp: the tool panel is on the left, the tool form is in the middle, and an automatic history of every analysis step is on the right.",
                },
                {
                    title: "Just Import Data to Get Started",
                    blurb: "Upload data from your machine or import public data from well-known repositories, such as UCSC or SRA.",
                },
                {
                    title: "Explore and Experiment",
                    blurb: "Easily try different tools and different parameters to see what works best. Every tool comes with help text and example data to easier understand what it does.",
                },
            ]}
        />

        <SectionBreak
            title="Use Popular and Published Tools"
            subtitle="The tools available in Galaxy Pro are high-quality, open-source, and published by the world-wide community."
        />
        <InfoSection
            image={toolPanels}
            imageOnLeft={false}
            imageShadow={false}
            facts={[
                {
                    title: "Best-of-breed Tools",
                    blurb: "Galaxy Pro makes a variety of well-known and popular tools available for RNA-Seq, ChIP-Seq, Variant analysis, and more.",
                },
                {
                    title: "No Software to Install",
                    blurb: "Create your analyses using Galaxy Pro directly from your web browser - there is no software to install, and nothing to configure on your computer.",
                },
                {
                    title: "Custom Tool Additions",
                    blurb: "If you rely on custom tools built in-house, or just prefer to use other tools that aren't yet integrated, Galaxy Pro is extensible and your tool can likely be added into the tool panel.",
                },
            ]}
        />

        <SectionBreak
            title="Enjoy Automatic Provenance"
            subtitle="Galaxy Pro automatically tracks all the analysis steps and tool parameters so you don't have to."
        />
        <InfoSection
            image={multiHistory}
            facts={[
                {
                    title: "History is Built Automatically",
                    blurb: "Galaxy Pro tracks every step of the analysis through the concept of a History, which automatically keeps up with the tools used, tool versions, and the value for every parameter.",
                },
                {
                    title: "Multiple and Shared Histories",
                    blurb: "You can have as many histories as you wish to organize your analyses, along with the ability to share any history with your collaborators who can then see the exact steps performed.",
                },
                {
                    title: "Automated Workflow Extraction",
                    blurb: "Once you have identified a set of analysis steps that you would like to repeat on different data extracting a repeatable workflow is a click away.",
                },
            ]}
        />
    </Layout>
);

export default LoginPage;
