import { Link } from "gatsby";
import React from "react";
import PropTypes from "prop-types";

export class HeaderSection extends React.Component {
    render() {
        return (
            <section className="hero is-primary is-medium">
                <div className="hero-body container has-text-centered">
                    <div className="container">
                        <h1 className="title is-1">{this.props.title}</h1>
                        <h2 className="subtitle">{this.props.subtitle}</h2>
                        <Link className="button is-warning" to="/pricing">
                            Try for Free!
                        </Link>
                    </div>
                </div>
            </section>
        );
    }
}

HeaderSection.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

export class SectionBreak extends React.Component {
    render() {
        return (
            <section className="hero is-small">
                <div className="hero-body container has-text-centered">
                    <div className="container">
                        <h1 className="title is-spaced is-2">{this.props.title}</h1>
                        <h2 className="subtitle">{this.props.subtitle}</h2>
                    </div>
                </div>
            </section>
        );
    }
}

SectionBreak.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

export class InfoSection extends React.Component {
    static defaultProps = {
        imageOnLeft: true,
        imageShadow: true,
    };
    render() {
        return (
            <section className="hero">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-vcentered">
                            {this.props.imageOnLeft && (
                                <div className="column is-half p-lg">
                                    <img
                                        className={"image" + (this.props.imageShadow ? " has-shadow" : "")}
                                        src={this.props.image}
                                    />
                                </div>
                            )}
                            <div className="column">
                                <div className="content m-l-lg">
                                    {this.props.facts.map((fact) => (
                                        <>
                                            <h2>{fact.title}</h2>
                                            <p>{fact.blurb}</p>
                                        </>
                                    ))}
                                </div>
                            </div>
                            {!this.props.imageOnLeft && (
                                <div className="column is-half p-lg">
                                    <img
                                        className={"image" + (this.props.imageShadow ? " has-shadow" : "")}
                                        src={this.props.image}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

InfoSection.propTypes = {
    image: PropTypes.image,
    facts: PropTypes.array,
    imageOnLeft: PropTypes.bool,
    imageShadow: PropTypes.bool,
};

export class UseCaseLink extends React.Component {
    render() {
        return (
            <section className="hero is-small">
                <div className="hero-body container">
                    <div className="column">
                        <div className="content m-l-lg">
                            {this.props.facts.map((fact) => (
                                <>
                                    <h2>{fact.title}</h2>
                                    <p>
                                        {fact.blurb}
                                        <Link to={"/" + fact.linkPage}>Read Use Case</Link>
                                    </p>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

UseCaseLink.propTypes = {
    facts: PropTypes.array,
};
